import { useState } from "preact/hooks";
import { Icons } from "../config/icons.tsx";

export function MobileMenuToggle() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[var(--color-primary)]"
      >
        <span class="sr-only">Open main menu</span>
        {!isOpen ? Icons.menuOpen : Icons.menuClose}
      </button>
      <div class={`${isOpen ? "block" : "hidden"} sm:hidden`}>
        <div class="pt-2 pb-3 space-y-1">
          <a href="/" class="border-transparent text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white block pl-2 pr-3 py-1.5 border-l-4 text-base font-medium">Home</a>
          <a href="/portfolio" class="border-transparent text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white block pl-2 pr-3 py-1.5 border-l-4 text-base font-medium">Portfolio</a>
          <a href="/technology" class="border-transparent text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white block pl-2 pr-3 py-1.5 border-l-4 text-base font-medium">Technology</a>
          <a href="/solutions" class="border-transparent text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white block pl-2 pr-3 py-1.5 border-l-4 text-base font-medium">Solutions</a>
          <a href="/about" class="border-transparent text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white block pl-2 pr-3 py-1.5 border-l-4 text-base font-medium">About</a>
          <a href="/news" class="border-transparent text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white block pl-2 pr-3 py-1.5 border-l-4 text-base font-medium">News</a>
          <a href="/blog" class="border-transparent text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white block pl-2 pr-3 py-1.5 border-l-4 text-base font-medium">Blog</a>
          <a href="/help" class="border-transparent text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white block pl-2 pr-3 py-1.5 border-l-4 text-base font-medium">Help</a>
          <div class="border-b border-gray-200 dark:border-gray-700 my-2" />
          <a href="/signin" class="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600 px-3 py-2 rounded-md text-sm font-medium transition-colors">Sign In</a>
          <a href="/#contact" class="bg-[var(--color-primary)] text-white hover:bg-opacity-90 px-3 py-2 rounded-md text-sm font-medium transition-colors">Sign Up</a>
        </div>
      </div>
    </>
  );
}
